<style>
    .question-image {
        width:70%;
        height:70%;
    }
    img {
        width:100%;
        height:100%;
    }
</style>
<div class="bootstrap-wrapper" *ngIf="!isSubmit" onmousedown="return false" onselectstart="return false">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-2">
        <!-- instructions -->
          <mat-card class="mt10">
            <mat-card-header>
              <mat-card-subtitle>Bạn đang làm <b>{{ questions[0].quiz.title }}</b></mat-card-subtitle>
              <mat-card-subtitle>Câu trả lời đã ghi nhận</mat-card-subtitle>
              <mat-card-subtitle *ngFor="let q of questions; let i = index">
                <span><a href="javascript:void(0);" (click)="goToQuestion(i)"> Câu {{ i + 1 }}:</a>
                  <a> {{ q.givenAnswer }}{{ getUnsure(i) }} </a>
                </span>
              </mat-card-subtitle>
            </mat-card-header>
          </mat-card>
      </div>
      <div class="col-md-8">
        <!-- questions -->
        <mat-card class="mt10" style="background-image: url('../../../../assets/copyright/copyright-hat360.png');">
          <mat-card-content>
            <span [innerHTML]="questions[currentQuestionIndex].description"></span>
            <div class="question-image" *ngIf="questions[currentQuestionIndex].image">
              <img [src]=getCurrentQuestionImage() />
            </div>
            <p>
              <u><b>Câu {{ currentQuestionIndex + 1 }}:</b></u>
              <span class="ml10" [innerHTML]="questions[currentQuestionIndex].content"></span>
            </p>
            <div class="watermark"> Watermark text</div>

            <div class="row mt20">
              <div class="col-md-6">
                <input
                  type="radio"
                  [value]="mapToABCD(questions[currentQuestionIndex], questions[currentQuestionIndex].option1)"
                  [name]="i"
                  [(ngModel)]="questions[currentQuestionIndex].givenAnswer"
                />
                <b>A.  </b>
                <span [innerHTML]="questions[currentQuestionIndex].option1"></span>
              </div>
              <div class="col-md-6">
                <input
                  type="radio"
                  [value]="mapToABCD(questions[currentQuestionIndex], questions[currentQuestionIndex].option2)"
                  [name]="i"
                  [(ngModel)]="questions[currentQuestionIndex].givenAnswer"
                />
                <b>B.  </b>
                <span [innerHTML]="questions[currentQuestionIndex].option2"></span>
              </div>
            </div>

            <div class="row mt20">
              <div class="col-md-6">
                <input
                  type="radio"
                  [value]="mapToABCD(questions[currentQuestionIndex], questions[currentQuestionIndex].option3)"
                  [name]="i"
                  [(ngModel)]="questions[currentQuestionIndex].givenAnswer"
                />
                <b>C.  </b>
                <span [innerHTML]="questions[currentQuestionIndex].option3"></span>
              </div>
              <div class="col-md-6">
                <input
                  type="radio"
                  [value]="mapToABCD(questions[currentQuestionIndex], questions[currentQuestionIndex].option4)"
                  [name]="i"
                  [(ngModel)]="questions[currentQuestionIndex].givenAnswer"
                />
                <b>D.  </b>
                <span [innerHTML]="questions[currentQuestionIndex].option4"></span>
              </div>
            </div>
            <br>
            <h5>&copy;2023 HAT Education. All Rights Reserved</h5>
          </mat-card-content>
        </mat-card>

        <div class="container text-center mt20 mb20">
          <span>
            <button *ngIf="hasPreviousQuestion()" style="float:left;" (click)="previousQuestion()" mat-raised-button color="accent">
            Câu hỏi trước
            </button>
            <button style="float:center;" (click)="markAsUnsure()" mat-raised-button color="accent">
            {{ getUnsureLabel() }}
            </button>
            <button *ngIf="hasNextQuestion()" style="float:right;" (click)="nextQuestion()" mat-raised-button color="accent">
            Câu hỏi tiếp theo
            </button>
          </span>
        </div>

      </div>

      <div class="col-md-2">
        <!-- progress -->
        <mat-card class="mt10">
          <mat-card-content>
            <h2 class="text-center" style="color:red;">{{ getFormattedTime() }}</h2>
<!--            <mat-spinner-->
<!--              mode="determinate"-->
<!--              color="accent"-->
<!--              style="margin: auto"-->
<!--              [value]="(timer / (this.questions.length * 75 * 2)) * 100"-->
<!--            ></mat-spinner>-->
          </mat-card-content>
          <mat-card-subtitle>
            Bài kiểm tra sẽ được tự động nộp khi hết thời gian
          </mat-card-subtitle>
        </mat-card>
        <div class="container text-center mt20 mb20">
          <button (click)="submitQuiz()" mat-raised-button color="accent">
            Nộp bài
          </button>
        </div>
      </div>

    </div>
  </div>
</div>

<!-- show the result -->
<div class="bootstrap-wrapper" *ngIf="isSubmit">
  <div class="row mt20">
    <div class="col-md-6 offset-md-3">
      <mat-card>
        <mat-card-header>
          <mat-card-title> Kết quả </mat-card-title>
        </mat-card-header>
        <mat-card-content class="text-center">
          <h2 [innerHTML]="categoryMarks"></h2>
          <h2 style="color:red">Tổng: {{ marksGot }} điểm</h2>
          <h2>Số câu trả lời đúng: {{ correctAnswers }}</h2>
          <h2>Số câu đã trả lời: {{ attempted }}</h2>
        </mat-card-content>
        <div class="container text-center mt20 mb20">
<!--          <button mat-raised-button color="primary">Print</button>-->
          <button
            mat-raised-button
            color="accent"
            [routerLink]="'/'"
          >
            Trang chủ
          </button>
          <button
            class="ml10"
            mat-raised-button
            color="accent"
            [routerLink]="'/user-dashboard/0'"
          >
            Mục đề thi
          </button>
          <button
            class="ml10"
            mat-raised-button
            color="accent"
            [routerLink]=getReviewLink()
          >
            Mục xem lại kết quả
          </button>
        </div>
      </mat-card>
    </div>
  </div>
</div>
