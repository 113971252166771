import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserQuizHistoryService } from 'src/app/services/userquizhistory.service';

@Component({
  selector: 'app-load-quiz',
  templateUrl: './load-quiz-history.component.html',
  styleUrls: ['./load-quiz-history.component.css'],
})
export class LoadQuizHistoryComponent implements OnInit {
  userId;
  userQuizHistories;
  hideDetails = null;
  showDetails = null;

  constructor(private _route: ActivatedRoute, private _userquizhistoryservice: UserQuizHistoryService) {}

  ngOnInit(): void {
    this._route.params.subscribe((params) => {
      this.userId = params.userId;
      console.log(this.userId);

      this._userquizhistoryservice.getUserQuizHistories(this.userId).subscribe(
        (data: any) => {
          this.userQuizHistories = data;
          console.log(this.userQuizHistories);
          this.hideDetails = Array.from({length: this.userQuizHistories.length}, (_, i) => true);
          console.log(this.hideDetails);
          this.showDetails = Array.from({length: this.userQuizHistories.length}, (_, i) => false);
          console.log(this.showDetails);
        },
        (error) => {
          alert('error in loading user quiz history data');
        }
      );
    });
  }

  updateShowAndHideDetails(index) {
    if (this.showDetails[index] === true)
    {
      this.hideDetails[index] = true;
      this.showDetails[index] = false;
    }
    else {
      this.hideDetails[index] = false;
      this.showDetails[index] = true;
    }
  }

  getDate(time) {
    return time.substring(8, 10) + '/' + time.substring(5, 7) + '/' + time.substring(0, 4)
  }

  getTimeWithVietnamFormat(time) {
    return this.getDate(time) + ' ' + time.substring(11, 19)
  }

  getReviewLink(i) {
      console.log(this.userQuizHistories[i]);
      console.log('/review/' + this.userQuizHistories[i].quiz.qId);
      return '/review/' + this.userQuizHistories[i].quiz.qId;
    }
}
