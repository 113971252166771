<div class="bootstrap-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-md-6 offset-md-3">
        <mat-card>
<!--          <div class="container text-center">-->
<!--            <img src="../../../assets/logo.png" alt="" />-->
<!--          </div>-->

<!--          <h1 class="text-center">Đăng kí tại đây!</h1>-->

          <!-- {{ user | json }} -->

          <form (ngSubmit)="formSubmit()">
            <!-- email -->
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Email</mat-label>
              <input
                required
                [(ngModel)]="user.username"
                name="username"
                type="email"
                matInput
                placeholder="Nhập vào đây"
              />
              <mat-hint>Email không được trùng với email đã đăng kí trước</mat-hint>
            </mat-form-field>

            <!-- Password field -->
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Mật khẩu</mat-label>
              <input
                required
                [(ngModel)]="user.password"
                name="password"
                type="password"
                matInput
                placeholder="Nhập vào đây"
              />
            </mat-form-field>

            <!-- first name  -->
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Tên</mat-label>
              <input
                [(ngModel)]="user.firstName"
                name="firstName"
                matInput
                placeholder="Nhập vào đây"
              />
            </mat-form-field>

            <!-- lastname -->
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Họ</mat-label>
              <input
                [(ngModel)]="user.lastName"
                name="lastName"
                matInput
                placeholder="Nhập vào đây"
              />
            </mat-form-field>

            <!-- phone number -->
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Số điện thoại</mat-label>
              <input
                [(ngModel)]="user.phone"
                name="phone"
                type="number"
                matInput
                placeholder="Nhập vào đây"
              />
            </mat-form-field>

            <div class="container text-center">
              <button type="submit" mat-raised-button color="accent">
                Đăng kí
              </button>
<!--              <button-->
<!--                type="button"-->
<!--                style="margin-left: 20px"-->
<!--                mat-raised-button-->
<!--                color="accent"-->
<!--              >-->
<!--                Xoá - nhập lại-->
<!--              </button>-->
            </div>
          </form>
        </mat-card>
      </div>
    </div>
  </div>
</div>
