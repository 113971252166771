import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CategoryService } from 'src/app/services/category.service';
import { UserQuizHistoryService } from 'src/app/services/userquizhistory.service';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-sidebar-user',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
  categories;
  user = null;
  userQuizHistories;

  constructor(
    private _cat: CategoryService,
    private _snack: MatSnackBar,
    private _login: LoginService,
    private _userQH: UserQuizHistoryService) {}

  ngOnInit(): void {
    this._cat.categories().subscribe(
      (data: any) => {
        this.categories = data;
      },
      (error) => {
        this._snack.open('Error in loading categories from server', '', {
          duration: 3000,
        });
      }
    );
    this.user = this._login.getUser();
//     console.log(this.user);
    this._userQH.getUserQuizHistories(this.user.id).subscribe(
      (data: any) => {
        this.userQuizHistories = data;
//         console.log(this.userQuizHistories);
      },
      (error) => {
        this._snack.open('Error in loading user quiz histories from server', '', {
          duration: 3000,
        });
      }
    );
  }
}
