import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from 'src/app/services/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
})
export class ResetPasswordComponent implements OnInit {
  constructor(private userService: UserService, private snack: MatSnackBar) {}

    public userPasswordReset = {
      username: '',
      password: ''
    };

    ngOnInit(): void {}

    formSubmit() {

      if (this.userPasswordReset.password == '' || this.userPasswordReset.password == null) {
        // alert('User is required !!');
        this.snack.open('Vui lòng nhập mật khẩu mới', '', {
          duration: 3000,
        });
        return;
      }

      //validate

      //addUser: userservice
      if (this.isEmail(this.userPasswordReset.username) == false)
      {
        console.log(this.isEmail(this.userPasswordReset.username))
        this.snack.open('Email không hợp lệ, vui lòng kiểm tra lại', '', {
          duration: 3000,
        });
        return;
      }

      this.userService.resetPassword(this.userPasswordReset).subscribe(
        (data: any) => {
          Swal.fire('Một bước nữa thôi', 'Bạn vui lòng kiểm tra email và nhấn vào đường link để xác nhận việc đặt lại mật khẩu.', 'success').then(
          (result) => {
                         if (result.isConfirmed) {
                           location.href = "/login"
                         }
                       });
        },
        (error) => {
          //error
          console.log(error);
          // alert('something went wrong');
          this.snack.open(error.error.text, '', {
            duration: 3000,
          });
        }
      );
    }

    isEmail(search)
    {
      var regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

      var searchfind = regexp.test(search);

      console.log(searchfind)
      return searchfind
    }
}
