<div class="bootstrap-wrapper"  onmousedown="return false" onselectstart="return false">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <mat-card class="mt20">
          <mat-card-content class="ml20">
            <h1>Hướng dẫn</h1>

            <ul>
              <!-- Let the time of each questions is  2 minutes -->
              <li>
                Bạn có
                <b> {{ quiz.numberOfQuestions * 2.5 }} phút </b>
<!--                This will need to be changed to * 2 instead, it's only for testing in MVP-->
                để hoàn thành bài kiểm tra.
              </li>
              <li>Bạn có thể làm lại bài kiểm tra này bao nhiêu lần cũng được.</li>
              <li>
                Bài kiểm tra này có <b>{{ quiz.numberOfQuestions }} câu hỏi.</b>
              </li>
              <li>
                Mỗi câu hỏi có trọng số là
                <b>{{ quiz.maxMarks / quiz.numberOfQuestions }} điểm</b>. Các
                 câu trả lời sai sẽ không bị trừ điểm.
              </li>
              <li>Tất cả các câu hỏi đều thuộc dạng trắc nghiệm.</li>
            </ul>

            <br />
            <h1>Làm bài kiểm tra</h1>
            <ul>
              <li>Nhấn nút <b>Bắt đầu</b> để bắt đầu làm bài.</li>
              <li>
                Đồng hồ sẽ bắt đầu tính giờ khi bài kiểm tra được bắt đầu.
              </li>
              <li>
                Bạn không thể tiếp tục bài kiểm tra nếu như bị ngắt quãng vì bất kì lí do gì.
                Bạn sẽ phải bắt đầu lại từ đầu.
              </li>
              <li>Nhấn nút <b>Câu hỏi tiếp theo</b> để đến với câu hỏi tiếp theo.</li>
              <li>Nhấn nút <b>Câu hỏi trước</b> để quay lại với câu hỏi trước.</li>
              <li>Nhấn nút <b>Nộp bài</b> để hoàn tất bài kiểm tra.</li>
            </ul>
          </mat-card-content>
          <mat-card-actions class="text-center">
            <!-- actions -->
            <button
              (click)="startQuiz()"
              class=""
              mat-raised-button
              color="accent"
            >
              Bắt đầu
            </button>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </div>
</div>
