<div class="bootstrap-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-md-6 offset-md-3">
        <mat-card>
          <form (ngSubmit)="formSubmit()">
            <!-- email -->
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Email</mat-label>
              <input
                required
                [(ngModel)]="userPasswordReset.username"
                name="username"
                type="email"
                matInput
                placeholder="Nhập vào đây"
              />
            </mat-form-field>

            <!-- Password field -->
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Mật khẩu mới</mat-label>
              <input
                required
                [(ngModel)]="userPasswordReset.password"
                name="password"
                type="password"
                matInput
                placeholder="Nhập vào đây"
              />
            </mat-form-field>

            <div class="container text-center">
              <button type="submit" mat-raised-button color="accent">
                Đặt lại mật khẩu
              </button>
            </div>
          </form>
        </mat-card>
      </div>
    </div>
  </div>
</div>
