<mat-card>
  <mat-action-list>
    <div mat-subheader>Menu</div>
    <button routerLink="/admin" mat-list-item>
      <mat-icon mat-list-icon>home</mat-icon> Home
    </button>
    <button mat-list-item routerLink="/admin/profile">
      <mat-icon mat-list-icon>account_circle</mat-icon> Profile
    </button>
    <button mat-list-item routerLink="/admin/categories">
      <mat-icon mat-list-icon>list</mat-icon> Categories
    </button>
    <button mat-list-item routerLink="/admin/add-category">
      <mat-icon mat-list-icon>add_circle_outline</mat-icon> Add Categories
    </button>
    <button mat-list-item routerLink="/admin/quizzes">
      <mat-icon mat-list-icon>quiz</mat-icon> Quizzes
    </button>
    <button mat-list-item routerLink="/admin/add-quiz">
      <mat-icon mat-list-icon>add</mat-icon> Add Quiz
    </button>
  </mat-action-list>
</mat-card>
