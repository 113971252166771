import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import baseUrl from './helper';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  //add user

  public addUser(user: any) {
    return this.http.post(`${baseUrl}/user/`, user);
  }

  public resetPassword(userPasswordReset: any) {
    return this.http.post(`${baseUrl}/user/reset-password-request`, userPasswordReset);
  }

  public changePassword(userPasswordChange: any) {
    return this.http.post(`${baseUrl}/user/change-password-request`, userPasswordChange);
  }
}
