<ngx-ui-loader
  fgsColor="#1DE9B6"
  pbColor="#1DE9B6"
  text="Đang tải nội dung, bạn vui lòng đợi trong giây lát..."
  pbThickness="6"
  textColor="#1DE9B6"
></ngx-ui-loader>

<div ngxUiLoaderBlurred blur="6">
  <app-navbar></app-navbar>

  <!-- dynamic content -->
  <router-outlet></router-outlet>
</div>
