import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import baseUrl from './helper';

@Injectable({
  providedIn: 'root',
})
export class UserQuizHistoryService {
  constructor(private _http: HttpClient) {}

  //add user quiz history
  public addUserQuizHistory(userQuizHistory) {
    return this._http.post(`${baseUrl}/userquizhistory/`, userQuizHistory);
  }

  //get all quiz histories for a given user
  public getUserQuizHistories(userid) {
    return this._http.get(`${baseUrl}/userquizhistory/user/${userid}`);
  }

  //get a specific quiz history for a given user and a given quiz
  public getSpecificUserQuizHistory(userid, quizid) {
    return this._http.get(`${baseUrl}/userquizhistory/user/${userid}/${quizid}`);
  }

  //update user quiz history
  public updateUserQuizHistory(userQuizHistory) {
    return this._http.put(`${baseUrl}/userquizhistory/`, userQuizHistory);
  }
}
