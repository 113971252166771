<mat-card *ngIf="user">
  <mat-card-content>
    <h1><button
      *ngIf="isNormalAuthority()"
      class="example-icon favorite-icon ml50"
      mat-button
      routerLink="/user-dashboard/0"
      aria-label="Example icon-button with heart icon"
      color="accent"
    >
      Quay về mục đề thi
    </button></h1>
  </mat-card-content>
  <!-- {{ user | json }} -->
  <mat-card-content>
    <div class="bootstrap-wrapper">
      <div class="container">
        <div class="container text-center">
          <img
            src="https://t4.ftcdn.net/jpg/03/46/93/61/360_F_346936114_RaxE6OQogebgAWTalE1myseY1Hbb5qPM.jpg"
            alt=""
            class="profile-image"
          />

          <h1 class="mt20">{{ user.lastName }} {{ user.firstName }}</h1>

        </div>

        <div class="row">
          <div class="col-md-10 offset-md-1">
            <!-- //table Details  -->
            <table class="table text-center">
              <tr>
                <td>Email</td>
                <td>{{ user.username }}</td>
              </tr>
              <tr>
                <td>Mật khẩu</td>
                <td>
                  <button
                    *ngIf="isNormalAuthority()"
                    class="text-center"
                    mat-raised-button
                    routerLink="/change-password"
                    aria-label="Example icon-button with heart icon"
                    color="accent"
                  >
                    Đổi mật khẩu
                  </button>
                </td>
              </tr>
              <tr>
                <td>Số điện thoại</td>
                <td>{{ user.phone }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
<!--  <mat-card-actions class="text-center">-->
<!--    <button mat-raised-button color="primary">UPDATE</button>-->
<!--    <button mat-raised-button color="accent">SHARE</button>-->
<!--  </mat-card-actions>-->
</mat-card>
