<div class="bootstrap-wrapper">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-2">
        <app-sidebar-user></app-sidebar-user>
      </div>

      <div class="col-md-10">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
