<mat-card>
  <h1>All Categories</h1>
  <mat-list>
    <mat-list-item *ngFor="let c of categories">
      <mat-icon mat-list-icon>category</mat-icon>
      <div mat-line>{{ c.title }}</div>
      <div mat-line>{{ c.description }}</div>
      <mat-divider></mat-divider>
    </mat-list-item>
  </mat-list>

  <div class="container text-center mt20">
    <button routerLink="/admin/add-category" mat-raised-button color="accent">
      Add New Category
    </button>
  </div>
</mat-card>
