import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from 'src/app/services/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css'],
})
export class SignupComponent implements OnInit {
  constructor(private userService: UserService, private snack: MatSnackBar) {}

  public user = {
    username: '',
    password: '',
    firstName: '',
    lastName: '',
    phone: '',
  };

  ngOnInit(): void {}

  formSubmit() {
//     console.log(this.user);
    if (this.user.username == '' || this.user.username == null) {
      // alert('User is required !!');
      this.snack.open('Vui lòng nhập email', '', {
        duration: 3000,
      });
      return;
    }

    if (this.user.password == '' || this.user.password == null) {
      // alert('User is required !!');
      this.snack.open('Vui lòng nhập mật khẩu', '', {
        duration: 3000,
      });
      return;
    }

    if (this.isEmail(this.user.username) == false)
          {
            console.log(this.isEmail(this.user.username))
            this.snack.open('Email không hợp lệ', '', {
              duration: 3000,
            });
            return;
          }

    this.userService.addUser(this.user).subscribe(
      (data: any) => {
        Swal.fire('Một bước nữa thôi', 'Bạn vui lòng kiểm tra email và nhấn vào đường link để xác nhận đăng kí.', 'success').then(
        (result) => {
                       if (result.isConfirmed) {
                         location.href = "/login"
                       }
                     });
      },
      (error) => {
        //error
        console.log(error);
        // alert('something went wrong');
        this.snack.open(error.error.text, '', {
          duration: 3000,
        });
      }
    );
  }

  isEmail(search)
  {
    var regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

    var serchfind = regexp.test(search);

    console.log(serchfind)
    return serchfind
  }
  //this.user
}
