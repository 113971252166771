<div class="bootstrap-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-md-6 offset-md-3">
        <mat-card>
          <form (ngSubmit)="formSubmit()">
            <!-- username field -->
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Email</mat-label>
              <input
                [(ngModel)]="loginData.username"
                name="username"
                required
                matInput
                placeholder="Nhập vào đây"
              />
            </mat-form-field>

            <!-- password field  -->

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Mật Khẩu</mat-label>
              <input
                [(ngModel)]="loginData.password"
                name="password"
                type="password"
                required
                matInput
                placeholder="Nhập vào đây"
              />
            </mat-form-field>

            <div class="container text-center">
              <button class="mb10" mat-raised-button color="accent" type="submit">
                Đăng nhập
              </button>
            </div>
            <div class="container text-center">
              <a>
                Bạn cần đăng nhập để truy cập bộ đề luyện thi đánh giá năng lực<br>Đại học Quốc gia
              </a>
            </div>
            <div class="container text-center">
              <a href="javascript:void(0);" color="accent" [routerLink]="'/signup'">
                Bạn chưa có tài khoản? Nhấn vào đây để đăng kí
              </a>
            </div>
            <div class="container text-center">
              <a href="javascript:void(0);" color="accent" [routerLink]="'/reset-password'">
                Quên mật khẩu? Nhấn vào đây để đặt lại
              </a>
            </div>
          </form>
        </mat-card>
        <br>
        <br>
      </div>
    </div>
  </div>
</div>
