import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { QuizService } from 'src/app/services/quiz.service';
import { LoginService } from 'src/app/services/login.service';
import { UserQuizHistoryService } from 'src/app/services/userquizhistory.service';

@Component({
  selector: 'app-load-quiz',
  templateUrl: './load-quiz.component.html',
  styleUrls: ['./load-quiz.component.css'],
})
export class LoadQuizComponent implements OnInit {
  catId;
  quizzes;
  showStart = null;
  showReview = null;
  user = null;

  constructor(
    private _route: ActivatedRoute,
    private _quiz: QuizService,
    private _login: LoginService,
    private _userQH: UserQuizHistoryService
    ) {}

  ngOnInit(): void {
    this._route.params.subscribe((params) => {
      this.catId = params.catId;
      this.user = this._login.getUser();
      if (this.catId == 0) {
        console.log('Load all the quiz');

        this._quiz.getActiveQuizzes().subscribe(
          (data: any) => {
            this.quizzes = data;
//             console.log(this.quizzes);

            this.showStart = Array.from({length: this.quizzes.length}, (_, i) => true);
            this.showReview = Array.from({length: this.quizzes.length}, (_, i) => false);

            for (let i = 0; i < this.quizzes.length; i++) {
              this._userQH.getSpecificUserQuizHistory(this.user.id, this.quizzes[i].qId).subscribe(
                (data: any) => {
//                   console.log('There is one quiz history for user id ' + this.user.id + ' and quiz id '
//                    + this.quizzes[i].qId + ', it is: ' + data.historyId);
                  this.updateShowStartAndShowReview(i, data.historyId);
                },
                (error) => {
                  alert('error in loading user quiz history data');
                }
              );
            }
          },
          (error) => {
            console.log(error);
            alert('error in loading all quizzes');
          }
        );
      } else {
        console.log('Load specific quiz');

        this._quiz.getActiveQuizzesOfCategory(this.catId).subscribe(
          (data: any) => {
            this.quizzes = data;
//             console.log(this.quizzes);

            this.showStart = Array.from({length: this.quizzes.length}, (_, i) => true);
            this.showReview = Array.from({length: this.quizzes.length}, (_, i) => false);

            for (let i = 0; i < this.quizzes.length; i++) {
              this._userQH.getSpecificUserQuizHistory(this.user.id, this.quizzes[i].qId).subscribe(
                (data: any) => {
//                   console.log('There is one quiz history for user id ' + this.user.id + ' and quiz id '
//                    + this.quizzes[i].qId + ', it is: ' + data.historyId);
                  this.updateShowStartAndShowReview(i, data.historyId);
                },
                (error) => {
                  alert('error in loading user quiz history data');
                }
              );
            }
          },
          (error) => {
            alert('error in loading quiz data');
          }
        );
      }
    });
  }

  updateShowStartAndShowReview(index, historyId) {
    if (historyId === null)
    {
      this.showStartAndHideReview(index);
    }
    else
    {
      this.showReviewAndHideStart(index);
    }
  }

  showStartAndHideReview(index) {
    this.showStart[index] = true;
    this.showReview[index] = false;
  }

  showReviewAndHideStart(index) {
    this.showStart[index] = false;
    this.showReview[index] = true;
  }
}
