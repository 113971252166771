<style>
    .question-image {
        width:70%;
        height:70%;
    }
    img {
        width:100%;
        height:100%;
    }
</style>
<div class="bootstrap-wrapper"  onmousedown="return false" onselectstart="return false">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-2">
        <!-- instructions -->
        <mat-card class="mt10">
          <mat-card-header>
            <mat-card-subtitle>Bạn đang xem lại <b>{{ questions[0].quiz.title }}</b></mat-card-subtitle>
            <mat-card-subtitle>Nhấp vào câu hỏi bạn muốn xem lại</mat-card-subtitle>
            <mat-card-subtitle *ngFor="let q of questions; let i = index">
              <a href="javascript:void(0);" (click)="goToQuestion(i)"> Câu {{ i + 1 }}</a>
            </mat-card-subtitle>
          </mat-card-header>
        </mat-card>
      </div>

      <div class="col-md-8">
        <!-- questions -->

        <mat-card class="mt10" style="background-image: url('../../../../assets/copyright/copyright-hat360.png');">
          <mat-card-content>
            <span [innerHTML]="questions[currentQuestionIndex].description"
                  onmousedown="return false" onselectstart="return false"></span>
            <div class="question-image" *ngIf="questions[currentQuestionIndex].image">
              <img [src]=getCurrentQuestionImage() />
            </div>
            <p>
              <u><b>Câu {{ currentQuestionIndex + 1 }}:</b></u>
              <span class="ml10" [innerHTML]="questions[currentQuestionIndex].content"
                    onmousedown="return false" onselectstart="return false"></span>
            </p>

            <div class="row mt20">
              <div class="col-md-6">
                <b>A.  </b>
                <span [innerHTML]="questions[currentQuestionIndex].option1"></span>
              </div>
              <div class="col-md-6">
                <b>B.  </b>
                <span [innerHTML]="questions[currentQuestionIndex].option2"></span>
              </div>
            </div>

            <div class="row mt20">
              <div class="col-md-6">
                <b>C.  </b>
                <span [innerHTML]="questions[currentQuestionIndex].option3"></span>
              </div>
              <div class="col-md-6">
                <b>D.  </b>
                <span [innerHTML]="questions[currentQuestionIndex].option4"></span>
              </div>
            </div>
            <br>
            <h5>&copy;2023 HAT Education. All Rights Reserved</h5>
          </mat-card-content>
        </mat-card>

        <div class="container text-center mt20 mb20">
          <span>
            <button style="float:left;" (click)="previousQuestion()" mat-raised-button color="accent">
            Câu hỏi trước
            </button>

            <span>
              <button style="width:170px" *ngIf="hideAnswers[currentQuestionIndex]" (click)="showAnswer(currentQuestionIndex)" mat-raised-button color="accent">
                Xem đáp án
              </button>
              <button style="width:170px" *ngIf="!hideAnswers[currentQuestionIndex]" (click)="hideAnswer(currentQuestionIndex)" mat-raised-button color="accent">
                Ẩn đáp án
              </button>
              <button class="ml10" style="width:170px" *ngIf="hideUserAnswers[currentQuestionIndex]" (click)="showUserAnswer(currentQuestionIndex)" mat-raised-button color="accent">
                Xem đáp án của bạn
              </button>
              <button class="ml10" style="width:170px" *ngIf="!hideUserAnswers[currentQuestionIndex]" (click)="hideUserAnswer(currentQuestionIndex)" mat-raised-button color="accent">
                Ẩn đáp án của bạn
              </button>
              <button class="ml10" style="width:170px" *ngIf="hideExplanations[currentQuestionIndex]" (click)="showExplanation(currentQuestionIndex)" mat-raised-button color="accent">
                Xem lời giải chi tiết
              </button>
              <button class="ml10" style="width:170px" *ngIf="!hideExplanations[currentQuestionIndex]" (click)="hideExplanation(currentQuestionIndex)" mat-raised-button color="accent">
                Ẩn lời giải chi tiết
              </button>
            </span>

            <button style="float:right;" (click)="nextQuestion()" mat-raised-button color="accent">
            Câu hỏi tiếp theo
            </button>
          </span>
        </div>
        <div class="row mt20" *ngIf="!hideAnswers[currentQuestionIndex]">
          <div class="col-md-10">
            Đáp án: {{ questions[currentQuestionIndex].answer }}
          </div>
        </div>
        <div class="row mt20" *ngIf="!hideUserAnswers[currentQuestionIndex]">
          <div class="col-md-10">
            {{ getUserAnswer(currentQuestionIndex) }}{{ timeForAnswers[currentQuestionIndex] }}.
          </div>
        </div>
        <p>
          <span class="ml10" *ngIf="!hideExplanations[currentQuestionIndex]" [innerHTML]="questions[currentQuestionIndex].explanation"></span>
        </p>
    </div>
  </div>
</div>
</div>
