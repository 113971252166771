<!-- <h1>this is navbar</h1> -->
<mat-toolbar color="accent" onmousedown="return false" onselectstart="return false">
  <a
    routerLink="/"
    mat-icon-button
  >
    <mat-icon>menu_book</mat-icon>
  </a>
  <a
    routerLink="/"
    mat-icon-button>
    Hat360
  </a>
  <span class="example-spacer"></span>
  <a
    [routerLink]="'/'"
    mat-button
  >
    Trang chủ
  </a>
  <a
    *ngIf="isLoggedIn"
    [routerLink]="'/user-dashboard/0/'"
    mat-button
  >
    Danh mục đề luyện thi
  </a>
  <a
    *ngIf="!isLoggedIn"
    [routerLink]="'/login'"
    mat-button
  >
    Đăng nhập
  </a>

  <a
    *ngIf="!isLoggedIn"
    [routerLink]="'/signup'"
    mat-button
  >
    Đăng kí
  </a>

  <a
    (click)="logout()"
    *ngIf="isLoggedIn"
    mat-button
    routerLink="/login"
  >
    Đăng xuất
  </a>

  <button
    *ngIf="isLoggedIn && isAdminAuthority()"
    mat-button
    routerLink="/admin/profile"
  >
    {{ user.username }}
  </button>

  <button
    *ngIf="isLoggedIn && isNormalAuthority()"
    mat-button
    routerLink="/profile"
  >
    {{ user.username }}
  </button>
</mat-toolbar>
