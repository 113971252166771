import { LocationStrategy } from '@angular/common';
import { ThisReceiver } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { QuestionService } from 'src/app/services/question.service';
import { QuizService } from 'src/app/services/quiz.service';
import { LoginService } from 'src/app/services/login.service';
import { UserQuizHistoryService } from 'src/app/services/userquizhistory.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.css'],
})
export class StartComponent implements OnInit {
  qid;
  questions;
  user = null;
  timerControl;

  currentQuestionIndex = 0;
  lastQuestionIndex = 0;
  unsureArr = null;
  timeForAnswers = null;

  marksGot = 0;
  correctAnswers = 0;
  attempted = 0;
  unattempted = 0;
  categoryMarks = "";
  startTime = null;

  isSubmit = false;

  timer: any;

  constructor(
    private locationSt: LocationStrategy,
    private _route: ActivatedRoute,
    private _question: QuestionService,
    private _quiz: QuizService,
    private _login: LoginService,
    private _userQH: UserQuizHistoryService
  ) {}

  ngOnInit(): void {
    this.preventBackButton();
    this.qid = this._route.snapshot.params.qid;
    this.user = this._login.getUser();
    this.loadQuestions();
  }
  loadQuestions() {
    this._question.getQuestionsOfQuizForTest(this.qid).subscribe(
      (data: any) => {
        this.questions = data;
        this.unsureArr = Array.apply(null, new Array(data.length)).map(()=> 0);
        this.timeForAnswers = Array.apply(null, new Array(data.length)).map(()=> 0);
        this.unattempted = this.questions.length;
        this.timer = this.questions.length * 75 * 2; //75 seconds per question, multiply by 2 is for MVP only ToDo: remove *2
        this.lastQuestionIndex = this.questions.length - 1;
        this.startTimer();
        this.startTime = new Date();
      },

      (error) => {
        console.log(error);
        Swal.fire('Error', 'Error in loading questions of quiz', 'error');
      }
    );
  }

  goToQuestion(i) {
    this.currentQuestionIndex = i;
  }

  mapToABCD(q, givenAnswer) {
    if (givenAnswer === q.option1) {
      return 'A';
    } else if (givenAnswer === q.option2) {
                 return 'B';
               } else if (givenAnswer === q.option3) {
                                 return 'C';
                               } else if (givenAnswer === q.option4) {
                                                 return 'D';
                                               } else {
                                                return null;
                                               }
  }

  preventBackButton() {
    history.pushState(null, null, location.href);
    this.locationSt.onPopState(() => {
      history.pushState(null, null, location.href);
    });
  }

  submitQuiz() {
    var numberOfUnAnswered = 0;
    for (let i = 0; i < this.questions.length; i++) {
      if (this.questions[i].givenAnswer == null)
      {
        numberOfUnAnswered += 1;
      }
    }

    Swal.fire({
      title: this.getSubmitWarning(numberOfUnAnswered),
      showCancelButton: true,
      confirmButtonText: `Nộp bài`,
      icon: 'info',
    }).then((e) => {
      if (e.isConfirmed) {
        this.evalQuiz();
      }
    });
  }

  hasPreviousQuestion() {
    return this.currentQuestionIndex !== 0;
  }

  hasNextQuestion() {
    return this.currentQuestionIndex !== this.lastQuestionIndex;
  }

  getCurrentQuestionImage() {
    return "../../../../../assets/exam-images/" + this.questions[this.currentQuestionIndex].image;
  }

  getSubmitWarning(numberOfUnAnswered) {
    if (numberOfUnAnswered > 0)
       {
         return 'Bạn có chắc muốn nộp bài? Bạn vẫn còn ' + numberOfUnAnswered + ' câu chưa trả lời';
       }
       else
       {
         return 'Bạn có chắc muốn nộp bài?';
       }
      }

  startTimer() {
    this.timerControl = window.setInterval(() => {
      //code
      if (this.timer <= 0) {
        this.evalQuiz();
        clearInterval(this.timerControl);
      } else {
        this.timer--;
        this.timeForAnswers[this.currentQuestionIndex]++;
      }
    }, 1000);
  }

  getFormattedTime() {
    let mm = Math.floor(this.timer / 60);
    let ss = this.timer - mm * 60;
    return `${mm} phút : ${ss} giây`;
  }

  nextQuestion() {
    if (this.currentQuestionIndex < (this.questions.length - 1))
    {
      this.currentQuestionIndex = this.currentQuestionIndex + 1;
    }
  }

  previousQuestion() {
    if (this.currentQuestionIndex > 0)
    {
      this.currentQuestionIndex = this.currentQuestionIndex - 1;
    }
  }

  getUnsureLabel() {
    if (this.unsureArr[this.currentQuestionIndex] == 0)
    {
      return "Không chắc";
    }
    else
    {
      return "Chắc rồi";
    }
  }

  markAsUnsure() {
    if (this.unsureArr[this.currentQuestionIndex] == 0)
    {
      this.unsureArr[this.currentQuestionIndex] = 1;
    }
    else
    {
      this.unsureArr[this.currentQuestionIndex] = 0;
    }
  }

  getUnsure(i) {
    if (this.unsureArr[i] == 0) {
      return null;
    }
    else {
      return " - Không chắc"
    }
  }

  evalQuiz() {
    //calculation
    //call to sever  to check questions
    this._question.evalQuiz(this.questions).subscribe(
      (data: any) => {
        this.marksGot = data.marksGot;
        this.correctAnswers = data.correctAnswers;
        this.categoryMarks = data.categoryMarks;
        this.attempted = data.attempted;
        this.isSubmit = true;
        let quiz = {qId: this.questions[0].quiz.qId};
        let user = {id: this.user.id};

        let recordedAnswers = "";
        for (var q of this.questions) {
          if (q.givenAnswer === null)
          {
            recordedAnswers += "X";
          }
          else
          {
            recordedAnswers += q.givenAnswer;
          }
        }

        let recordedDurationForAnswers = "";

        recordedDurationForAnswers += this.timeForAnswers[0];
        for (let i = 1; i < this.timeForAnswers.length; i++) {
          recordedDurationForAnswers += ("," + this.timeForAnswers[i]);
        }
//         this.timer = null;
        clearInterval(this.timerControl);

        let userQuizHistory = {
            marks: this.marksGot,
            numGivenAnswers: this.attempted,
            categoryMarks: this.categoryMarks,
            startTime: this.startTime,
            endTime: new Date(),
            quiz: quiz,
            user: user,
            givenAnswers: recordedAnswers,
            durationForAnswers: recordedDurationForAnswers
        };
        this._userQH.addUserQuizHistory(userQuizHistory).subscribe();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getReviewLink() {
    return '/user-quiz-history/' + this.user.id;
  }
}
