<mat-card>
  <h1>Add New Quiz</h1>
  <div class="container">
    <div class="row">
      <div class="col-md-8 offset-md-2">
        <form (ngSubmit)="addQuiz()">
          <!-- title field  -->
          <mat-form-field class="w100" appearance="fill">
            <mat-label>Enter Title</mat-label>
            <input
              [(ngModel)]="quizData.title"
              type="text"
              name="title"
              placeholder="Enter here"
              matInput
              required
            />
          </mat-form-field>

          <!-- description field  -->

          <mat-form-field class="w100" appearance="fill">
            <mat-label>Enter Description </mat-label>
            <textarea
              [(ngModel)]="quizData.description"
              type="text"
              name="description"
              placeholder="Enter here"
              matInput
              rows="5"
            ></textarea>
          </mat-form-field>

          <div class="row">
            <div class="col-md-6">
              <!-- //max marks -->
              <mat-form-field class="w100" appearance="fill">
                <mat-label>Maximum Marks</mat-label>
                <input
                  [(ngModel)]="quizData.maxMarks"
                  type="text"
                  name="maxMarks"
                  placeholder="Enter here"
                  matInput
                />
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <!-- number of question to be shown  -->
              <mat-form-field class="w100" appearance="fill">
                <mat-label>Number of Question</mat-label>
                <input
                  [(ngModel)]="quizData.numberOfQuestions"
                  type="text"
                  name="numberOfQuestions"
                  placeholder="Enter here"
                  matInput
                />
              </mat-form-field>
            </div>
          </div>

          <!-- active field   -->

          <mat-slide-toggle
            name="active"
            [(ngModel)]="quizData.active"
            class="mt20"
          >
            Publish Status
          </mat-slide-toggle>

          <br />
          <!-- //category select  -->
          <mat-form-field appearance="fill" class="mt20 w100">
            <mat-label>Categories</mat-label>
            <mat-select name="category" [(ngModel)]="quizData.category.cid">
              <mat-option *ngFor="let c of categories" [value]="c.cid">
                {{ c.title }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div class="container text-center">
            <button mat-raised-button color="accent" type="submit">Add</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</mat-card>

<!-- {{ quizData | json }} -->
