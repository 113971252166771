<div class="bootstrap-wrapper" onmousedown="return false" onselectstart="return false">
  <mat-card>
    <mat-action-list>
      <!--    <div mat-subheader>Available Categories</div>-->

      <!--    <button mat-list-item routerLink="/user-dashboard/0">-->
      <!--&lt;!&ndash;      <mat-icon mat-list-icon>quiz</mat-icon>&ndash;&gt;-->
      <!--      Tất Cả-->
      <!--    </button>-->
      <button
        [routerLink]="'/user-dashboard/' + c.cid"
        mat-list-item
        *ngFor="let c of categories"
      >
        <!--      <mat-icon mat-list-icon>quiz</mat-icon>-->
        {{ c.title }}
      </button>
      <button mat-list-item [routerLink]="'/user-quiz-history/' + user.id">
        <!--      <mat-icon mat-list-icon>quiz</mat-icon>-->
        Kết quả
      </button>
    </mat-action-list>
  </mat-card>
  <!-- {{ categories | json }} -->
</div>

