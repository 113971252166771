import { LocationStrategy } from '@angular/common';
import { ThisReceiver } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { QuestionService } from 'src/app/services/question.service';
import { QuizService } from 'src/app/services/quiz.service';
import { LoginService } from 'src/app/services/login.service';
import { UserQuizHistoryService } from 'src/app/services/userquizhistory.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-start',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.css'],
})
export class ReviewComponent implements OnInit {
  qid;
  questions;
  user = null;
  userQuizHistory;

  currentQuestionIndex = 0;
  timeForAnswers = null;
  hideAnswers = null;
  hideUserAnswers = null;
  hideExplanations = null;

  constructor(
    private locationSt: LocationStrategy,
    private _route: ActivatedRoute,
    private _question: QuestionService,
    private _quiz: QuizService,
    private _login: LoginService,
    private _userQH: UserQuizHistoryService
  ) {}

  ngOnInit(): void {
    this.qid = this._route.snapshot.params.qid;
    this.user = this._login.getUser();
    this._userQH.getSpecificUserQuizHistory(this.user.id, this.qid).subscribe(
      (data: any) => {
        this.userQuizHistory = data;
        this.timeForAnswers = Array.apply(null, new Array(data.length)).map(()=> "");
        let recordedDurationForAnswers = data.durationForAnswers.split(',');
        for (let i = 0; i < recordedDurationForAnswers.length; i++)
        {
          if (this.userQuizHistory.givenAnswers[i] !== 'X')
          {
            this.timeForAnswers[i] = this.convertToTimeString(parseInt(recordedDurationForAnswers[i]));
          }
        }
      },
      (error) => {
        alert('error in loading user quiz history data');
      }
    );;
    this.loadQuestions();
  }
  loadQuestions() {
    this._question.getQuestionsOfQuizForTest(this.qid).subscribe(
      (data: any) => {
        this.questions = data;
        this.hideAnswers = Array.apply(null, new Array(data.length)).map(()=> true);
        this.hideUserAnswers = Array.apply(null, new Array(data.length)).map(()=> true);
        this.hideExplanations = Array.apply(null, new Array(data.length)).map(()=> true);
      },
      (error) => {
        console.log(error);
        Swal.fire('Error', 'Error in loading questions of quiz', 'error');
      }
    );
  }

  convertToTimeString(timeInSeconds) {
    console.log("converting time string...");
    let mm = Math.floor(timeInSeconds / 60);
    console.log(mm);
    let ss = timeInSeconds - mm * 60;
    console.log(ss);
    if (mm > 0)
    {
      return `, bạn đã dành ${mm} phút ${ss} giây để trả lời câu hỏi này`;
    }
    else
    {
      return `, bạn đã dành ${ss} giây để trả lời câu hỏi này`;
    }
  }

  goToQuestion(i) {
    this.currentQuestionIndex = i;
  }

  getUserAnswer(questionIndex) {
    var givenAnswer = this.userQuizHistory.givenAnswers[questionIndex];
    if (givenAnswer === 'X')
    {
      return 'Bạn đã không trả lời câu hỏi này';
    }
    else
    {
      return 'Đáp án của bạn là ' + givenAnswer;
    }
  }

  mapToABCD(q, givenAnswer) {
    if (givenAnswer === q.option1) {
      return 'A';
    } else if (givenAnswer === q.option2) {
                 return 'B';
               } else if (givenAnswer === q.option3) {
                                 return 'C';
                               } else if (givenAnswer === q.option4) {
                                                 return 'D';
                                               } else {
                                                return null;
                                               }
  }

  getCurrentQuestionImage() {
    return "../../../../../assets/exam-images/" + this.questions[this.currentQuestionIndex].image;
  }

  nextQuestion() {
    if (this.currentQuestionIndex < (this.questions.length - 1))
    {
      this.currentQuestionIndex = this.currentQuestionIndex + 1;
    }
  }

  previousQuestion() {
    if (this.currentQuestionIndex > 0)
    {
      this.currentQuestionIndex = this.currentQuestionIndex - 1;
    }
  }

  hideAnswer(index) {
    this.hideAnswers[index] = true;
  }

  showAnswer(index) {
    this.hideAnswers[index] = false;
  }

  hideUserAnswer(index) {
    this.hideUserAnswers[index] = true;
  }

  showUserAnswer(index) {
    this.hideUserAnswers[index] = false;
  }

  showExplanation(index) {
    this.hideExplanations[index] = false;
  }

  hideExplanation(index) {
    this.hideExplanations[index] = true;
  }

}
