<div class="bootstrap-wrapper">
  <div class="container-fluid">
    <div class="row mt20" *ngIf="userQuizHistories">
      <div *ngFor="let q of userQuizHistories, let index = index">
          <div class="mb5 mt5 ml5 mr5">
            <button
              *ngIf="!showDetails[index]"
              (click)="updateShowAndHideDetails(index)"
              mat-raised-button
              style="width:280px !important;"
              color="accent">
              {{ q.quiz.title }} - {{ getDate(q.startTime) }}
            </button>
            <button *ngIf="showDetails[index]" (click)="updateShowAndHideDetails(index)" mat-stroked-button style="width:280px !important;" color="accent">
              {{ q.quiz.title }} - {{ getDate(q.startTime) }}
            </button>
            <mat-card style="width:280px !important; height:350px !important" *ngIf="showDetails[index]">
              <mat-card-content *ngIf="showDetails[index]">
                <p class="truncate">Số câu hỏi: {{ q.quiz.numberOfQuestions }} - Điểm số tối đa: {{ q.quiz.maxMarks }}</p>
                <p class="truncate">Tổng điểm đạt được: {{ q.marks }} điểm</p>
                <p class="truncate" [innerHTML]="q.categoryMarks"></p>
                <p class="truncate">Số câu đã trả lời: {{ q.numGivenAnswers }} câu</p>
                <p class="truncate">Bắt đầu lúc {{ getTimeWithVietnamFormat(q.startTime) }}</p>
                <p class="truncate">Nộp bài lúc {{ getTimeWithVietnamFormat(q.endTime) }}</p>
                <a class="truncate" href="javascript:void(0);" color="accent" [routerLink]=getReviewLink(index)>Xem lại</a>
              </mat-card-content>

            </mat-card>

          </div>
      </div>
      <div class="col-md-12" *ngIf="userQuizHistories.length == 0">
        <mat-card>
          <mat-card-content>
            <h1>Bạn chưa hoàn thành đề nào.</h1>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>
