<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Exam Practice Platform</title>
  <style>
    @keyframes fadeIn {
        from { opacity: 0; }
        to { opacity: 1; }
    }

    @keyframes fadeUp {
        from { opacity: 0.5; }
        to { opacity: 1; }
    }

    body {
        font-family: Arial, sans-serif;
        margin: 0;
        padding: 0;
        background: url("../../../assets/background-images/background_1.jpg") no-repeat center center fixed;
        background-size: cover;
        animation: fadeIn 1s ease-in;
        color: #333;
    }

    header {
        background-color: rgba(0, 115, 230, 0.8);
        color: white;
        padding: 1rem 0;
        text-align: center;
        animation: fadeIn 1s ease-in;
    }

    .container {
        width: 80%;
        margin: 0 auto;
        animation: fadeIn 1s ease-in;
    }

    .intro {
        background-color: rgba(255, 255, 255, 0.9);
        padding: 2rem;
        margin-top: 2rem;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        animation: fadeIn 1s ease-in;
    }

    .intro h1 {
        margin-top: 0;
    }

    footer {
        background-color: rgba(0, 115, 230, 0.8);
        color: white;
        text-align: center;
        padding: 1rem 0;
        position: fixed;
        width: 100%;
        bottom: 0;
        animation: fadeIn 1s ease-in;
    }

    .animated {
        animation: fadeIn 1s ease-in;
    }

    .btn {
        display: inline-block;
        padding: 1rem 2rem;
        background-color: #0073e6;
        color: white;
        text-align: center;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        text-decoration: none;
        margin-top: 1rem;
        margin-right: 1rem;
        animation: fadeIn 1s ease-in;
    }

    .btn:hover {
        background-color: #005bb5;
    }
  </style>
</head>
<body>
<header class="animated">
  <h1>Chào mừng bạn đến với Hat360!</h1>
</header>
<div class="container animated">
  <div class="intro">
    <h1>Sẵn sàng để đạt kết quả tốt nhất!</h1>
    <p>Chào mừng bạn đến với nền tảng hỗ trợ luyện thi đánh giá năng lực đại học Quốc gia. Nền tảng của chúng tôi được thiết kế dành riêng cho các bạn học sinh lớp 10, 11, hoặc 12 có kế hoạch tham gia kì thi đánh giá năng lực đại học Quốc gia thành phố Hồ Chí Minh.</p>
    <p>Luyện tập chính là chìa khoá để bạn đạt được kết quả tốt nhất. Hat360 ở đây để giúp bạn! Không những tổng hợp các đề luyện thi sát với thực tế giúp bạn làm quen với cấu trúc đề.</p>
    <p>Bạn chưa biết gì về kỳ thi đánh giá năng lực đại học Quốc gia thành phố Hồ Chí Minh? Không sao cả, nền tảng Hat360 giúp bạn làm quen với cấu trúc đề thông qua bộ đề luyện tập sát với thực tế.</p>
    <p>Bạn đã thi nhưng kết quả không được như ý? Nền tảng Hat360 ứng dụng trí tuệ nhân tạo để giúp bạn đánh giá điểm mạnh và chưa mạnh của mình để tập trung cải thiện những mảng kiến thức cần thiết, tránh lan man lãng phí thời gian.</p>
    <p>Tập trung vào bạn, giúp bạn trở thành phiên bản tốt nhất của mình, là mục tiêu của Hat360.</p>
    <a [routerLink]="'/signup'" class="btn">Đăng ký ngay, hoàn toàn miễn phí!</a>
    <a [routerLink]="'/login'" class="btn">Bạn đã có tài khoản? Đăng nhập ngay thôi!</a>
  </div>
</div>
<footer class="animated">
  <p>&copy; 2024 Hat360 Education</p>
</footer>
</body>
</html>
