<div class="bootstrap-wrapper" onmousedown="return false" onselectstart="return false">
  <div class="container-fluid">
    <div class="row mt5" *ngIf="quizzes">
      <div class="row ml5 mr5 mb5 mt5" *ngFor="let q of quizzes, let index = index">
          <span>
                <button
                  mat-raised-button
                  matTooltip="Bạn chưa làm đề này, nhấn để bắt đầu"
                  matTooltipClass="mat-tooltip"
                  style="width:150px !important;"
                  color="accent"
                  [routerLink]="'/user-dashboard/instructions/' + q.qId"
                  *ngIf="showStart[index]"
                >
                  {{ q.title }}
                </button>
                <button
                  mat-raised-button
                  matTooltip="Bạn đã làm đề này, nhấn để đi tới mục review"
                  matTooltipClass="mat-tooltip"
                  style="width:150px !important;"
                  color="primary"
                  [routerLink]="'/review/' + q.qId"
                  *ngIf="showReview[index]"
                >
                  {{ q.title }}
                </button>
          </span>
      </div>

      <div class="col-md-12" *ngIf="quizzes.length == 0">
        <mat-card>
          <mat-card-content>
            <h1>Không có đề nào</h1>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>
